import React from 'react';

import loadingIcon from 'assets/images/loading.gif';
import mapModifiers from 'utils/functions';

type LoadingProps = {
  variant?: 'fullscreen' | 'relative';
};

const Loading: React.FC<LoadingProps> = ({ variant }) => (
  <div className={mapModifiers('a-loading', variant)}>
    <img
      className="a-button_loadingIcon"
      src={loadingIcon}
      alt="loading"
    />
  </div>
);

export default Loading;
