import { useEffect } from 'react';

// Set the name of the hidden property and the change event for visibility
let hidden: string;
let visibilityChange: string;

if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof (document as any).msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof (document as any).webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

const useResumeFg = (cbVisibility: () => void, cbHide?: () => void) => {
  useEffect(() => {
    function handleVisibilityChange() {
      if ((document as any)[hidden] && cbHide) {
        cbHide();
      } else {
        cbVisibility();
      }
    }

    // Handle page visibility change
    document.addEventListener(visibilityChange, handleVisibilityChange, false);

    return () => document.removeEventListener(
      visibilityChange,
      handleVisibilityChange,
      false,
    );
  }, [cbHide, cbVisibility]);
};

export default useResumeFg;
