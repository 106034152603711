import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getInteractiveActivityQuestionService } from 'services/questions';
import { QuestionInteractiveResponse } from 'services/questions/type';

export type SocketLiveQuestionsTypes = {
  approvedAt: string;
  id: number;
  member: null;
  memberId: number;
  question: string;
  speaker: {
    shortname: string;
    fullname: string;
  },
  toSpeaker: number;
};

export type SocketInteractiveTypes = {
  broadcast_at: string
  created_at: string
  display_order: number
  id: number
  no_answer: number
  question: string
  updated_at: string
  yes_answer: number
};

interface QuestionState {
  questions: SocketLiveQuestionsTypes[];
  interactives: SocketInteractiveTypes[];
  timeBroadcasted?: number;
}

const initialState: QuestionState = {
  questions: [],
  interactives: []
};

export const getQuestionInteractiveAction = createAsyncThunk<
  QuestionInteractiveResponse,
  void,
  { rejectValue: ErrorResponse }
>(
  'configs/getQuestionInteractiveAction',
  async (_, { rejectWithValue }) => {
    try {
      const data = await getInteractiveActivityQuestionService();
      return data;
    } catch (error) {
      return rejectWithValue(error as ErrorResponse);
    }
  },
);

export const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    setInitQuestions: ($state, action: PayloadAction<SocketLiveQuestionsTypes[]>) => {
      $state.questions = action.payload;
    },
    setQuestions: ($state, action: PayloadAction<SocketLiveQuestionsTypes>) => {
      const clone = [...$state.questions];
      $state.questions = [action.payload, ...clone];
    },
    setInteractives: ($state, action: PayloadAction<{
      timeBroadcasted: number,
      data: SocketInteractiveTypes[]
    }>) => {
      if (!$state.timeBroadcasted) {
        $state.interactives = action.payload.data;
        $state.timeBroadcasted = action.payload.timeBroadcasted;
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(getQuestionInteractiveAction.fulfilled, (
      $state,
      action: PayloadAction<QuestionInteractiveResponse>
    ) => {
      if (action.payload) {
        $state.interactives = action.payload.interactiveActivityQuestion.map((item) => ({
          broadcast_at: item.broadcastAt,
          created_at: item.createdAt,
          display_order: item.displayOrder,
          id: item.id,
          no_answer: item.noAnswer,
          question: item.question,
          updated_at: item.updatedAt,
          yes_answer: item.yesAnswer,
        }));
        if (action.payload.timeBroadcasted) {
          $state.timeBroadcasted = action.payload.timeBroadcasted;
        }
      }
    });
  },
});

export const { setQuestions, setInteractives, setInitQuestions } = questionSlice.actions;

export default questionSlice.reducer;
