import {
  AnswerSurveyTypes,
  ComboCurrentSessionTypes,
  CommentAllLivestreamSessionsCurrentTypes,
  CommentCurrentSessionTypes,
  CommentLSParamsTypes, LivestreamSessionsCurrentTypes,
  OrderComboResponseTypes,
  OrderLSParamsTypes, VoucherCurrentSessionTypes,
  ZnsClickLiveStreamSessionsByCodeTypes
} from './types';

import axiosInstance from 'services/common/instance';

const getLiveStreamSessionsCurrentService = async (): Promise<LivestreamSessionsCurrentTypes> => {
  const res = await axiosInstance.get('livestream-sessions/current');
  return res.data.data;
};

export const getCurrentCombosLiveStreamSessionsByIdService = async (id: number)
  : Promise<ComboCurrentSessionTypes> => {
  const res = await axiosInstance.get(`livestream-sessions/${id}/combos/current`);
  return res.data.data;
};

export const getCurrentVouchersLiveStreamSessionsByIdService = async (id: number)
  : Promise<VoucherCurrentSessionTypes> => {
  const res = await axiosInstance.get(`livestream-sessions/${id}/vouchers/current`);
  return res.data.data;
};

export const createOrdersLiveStreamSessionsByIdService = async (
  id: number,
  params: OrderLSParamsTypes
): Promise<OrderComboResponseTypes> => {
  const res = await axiosInstance.post(`livestream-sessions/${id}/orders`, params);
  return res.data.data;
};

export const getCommentsLiveStreamSessionsByIdService = async (
  id: number,
  params: BaseFilterParams
): Promise<APIPaginationResponse<CommentCurrentSessionTypes[]>> => {
  const res = await axiosInstance.get(`livestream-sessions/${id}/comments`, { params });
  return res.data;
};

export const createCommentsLiveStreamSessionsByIdService = async (
  id: number,
  params: CommentLSParamsTypes
): Promise<LivestreamSessionsCurrentTypes> => {
  const res = await axiosInstance.post(`livestream-sessions/${id}/comments`, params);
  return res.data;
};

export const getCommentsLivestreamByIdService = async (
  id: number
): Promise<Array<CommentAllLivestreamSessionsCurrentTypes>> => {
  const res = await axiosInstance.get(`livestream-sessions/${id}/comments/all`);
  return res.data;
};

export const znsClickLiveStreamSessionsByCodeService = async (
  code: string,
): Promise<ZnsClickLiveStreamSessionsByCodeTypes> => {
  const res = await axiosInstance.post(`livestream-sessions/zns-clicked/${code}`);
  return res.data.data;
};

export const currentForSimulatorLiveStreamSessionsByCodeService = async (
  id: number,
): Promise<ComboCurrentSessionTypes> => {
  const res = await axiosInstance.get(`livestream-sessions/${id}/combos/current-for-simulator`);
  return res.data.data;
};

export const surveyLiveStreamSessionsService = async (params: AnswerSurveyTypes): Promise<void> => {
  const res = await axiosInstance.post('livestream-sessions/survey/answer', params);
  return res.data;
};

export default getLiveStreamSessionsCurrentService;
