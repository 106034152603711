import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommentCurrentSessionTypes } from 'services/livestream/types';

interface CommentState {
  data: Array<CommentCurrentSessionTypes>;
  isLoading: boolean;
  idDeleteComment?: number;
}

const initialState: CommentState = {
  data: [],
  isLoading: false
};

// export const getLiveStreamCurrentAction = createAsyncThunk<
//   CommentCurrentSessionParamsTypes,
//   void,
//   { rejectValue: ErrorResponse }
// >('commentsReducer/getLiveStreamCurrent', async (param, { rejectWithValue }) => {
//   try {
//     const res = await getCommentsLiveStreamSessionsByIdService(param);
//     return res;
//   } catch (error) {
//     return rejectWithValue(error as ErrorResponse);
//   }
// });

export const commentsSlice = createSlice({
  name: 'commentsReducer',
  initialState,
  reducers: {
    pushComment: ($state, action: PayloadAction<CommentCurrentSessionTypes>) => {
      $state.data.unshift(action.payload);
    },
    deleteComment: ($state, action: PayloadAction<{ id: number }>) => {
      $state.data = $state.data.filter((item) => item.id !== action.payload.id);
      $state.idDeleteComment = action.payload.id;
    },
    resetComment: ($state) => {
      $state.data = [];
    },
  },
  extraReducers() { }
});

export const {
  pushComment, deleteComment, resetComment
} = commentsSlice.actions;

export default commentsSlice.reducer;
