import {
  ConditionalListTypes, ForgotPasswordParamsTypes, VerifyOTPParamsTypes,
  LoginParamsTypes, RequestPhoneParamsTypes, UserInfoDataTypes
} from './type';

import axiosInstance from 'services/common/instance';

export const forgotPasswordService = async (params: ForgotPasswordParamsTypes): Promise<any> => {
  const res = await axiosInstance.post('members/forgot-password', params);
  return res.data;
};

const loginEventService = async (params: LoginParamsTypes): Promise<UserInfoDataTypes> => {
  const res = await axiosInstance.post('members/login', params);
  return res.data.data;
};

export const sendOTPService = async (params: RequestPhoneParamsTypes)
  : Promise<{ token: string }> => {
  const res = await axiosInstance.post('members/send-otp', params);
  return res.data.data;
};

export const verifyOTPService = async (params: VerifyOTPParamsTypes): Promise<any> => {
  const res = await axiosInstance.post('members/verify-otp', params);
  return res.data;
};

export const getConditionalListService = async (): Promise<ConditionalListTypes> => {
  const res = await axiosInstance.post('members/conditions-list');
  return res.data.data;
};

export default loginEventService;
