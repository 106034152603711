/* eslint-disable */
import 'App.scss';

import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import {
  Navigate, Route, RouteProps,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import eventConfig from 'configs';
import MainLayoutContainer from 'containers/mainLayout';
import useInAppDetect from 'hooks/useInAppDetect';
const BaoMat = React.lazy(() => import('pages/BaoMat'));
const EventWaiting = React.lazy(() => import('pages/EventWaiting'));
const EventWaiting2 = React.lazy(() => import('pages/EventWaiting2'));
const Livestream = React.lazy(() => import('pages/Livestream'));
import Container from 'components/organisms/Container';
const KV = React.lazy(() => import('pages/KV'));
const Logout = React.lazy(() => import('pages/Logout'));
const ZnsLink = React.lazy(() => import('pages/ZnsLink'));
const Phase2 = React.lazy(() => import('pages/phase2'));
const Simulator = React.lazy(() => import('pages/Simulator'));
import { getTokenSession } from 'services/common/storage';
import { store } from 'store';
import Loading from 'components/atoms/Loading';

const routes = [
  {
    paths: '/',
    element: <EventWaiting2 />,
    isAuth: false,
  },
  {
    paths: eventConfig.SLUG_PARAMS.EVENTWAITING1,
    element: <EventWaiting />,
    isAuth: true,
  },
  // {
  //   paths: eventConfig.SLUG_PARAMS.LOGIN,
  //   element: <Login />,
  //   isAuth: false,
  // },
  // {
  //   paths: eventConfig.SLUG_PARAMS.WAITING,
  //   element: <Waiting />,
  //   isAuth: true,
  // },
  {
    paths: eventConfig.SLUG_PARAMS.LIVESTREAM,
    element: <Livestream />,
    isAuth: true,
  },
  // {
  //   paths: eventConfig.SLUG_PARAMS.SURVEY,
  //   element: <Survey />,
  //   isAuth: true,
  // },
  // {
  //   paths: eventConfig.SLUG_PARAMS.CME,
  //   element: <Cme />,
  //   isAuth: true,
  // },
  // {
  //   paths: eventConfig.SLUG_PARAMS.FOYER,
  //   element: <Foyer />,
  //   isAuth: true,
  // },
];

const routesPhase2 = [
  {
    paths: '/',
    element: <Phase2 />,
    isAuth: false,
  },
  {
    paths: eventConfig.SLUG_PARAMS.EVENTWAITING1,
    element: <KV />,
    isAuth: true,
  },
  {
    paths: eventConfig.SLUG_PARAMS.LIVESTREAM,
    element: <Livestream />,
    isAuth: true,
  },
  {
    paths: eventConfig.SLUG_PARAMS.SIMULATOR,
    element: <Simulator />,
    isAuth: true,
  }
];

interface PrivateRouteProps extends RouteProps {
  outlet: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ outlet }) => {
  const token = getTokenSession();
  if (!token) return <Navigate to="/" />;
  return outlet;
};

const App: React.FC = () => {
  const inApp = useInAppDetect();

  if (inApp) {
    return inApp === 'ios' ? (
      <Container>
        <div className="inApp">
          <span>
            Vui lòng nhấn và giữ
            {' '}
            <a href={window.location.href}>liên kết này</a>
            {' '}
            trong 02 giây để chuyển đổi trình duyệt phù hợp và trải nghiệm
          </span>
        </div>
      </Container>
    ) : (
      <Container>
        <div className="inApp">
          <span>
            Vui lòng nhấn vào
            {' '}
            <a href={`intent://${window.location.host}${window.location.pathname}${window.location.search}#Intent;scheme=${window.location.protocol.replace(/\W/g, '')};action=android.intent.action.VIEW;end;`}>liên kết này</a>
            {' '}
            để chuyển đổi trình duyệt phù hợp và trải nghiệm.
          </span>
        </div>
      </Container>
    );
  }

  return (
    <Suspense fallback={<Loading variant="fullscreen" />}>
      <Routes>
        <Route path={eventConfig.SLUG_PARAMS.BAO_MAT} element={<BaoMat />} />
        {/* <Route path="SlxkKOl7Q6jKjvdV" element={<SlxkK0l7Q6jKjvdV />} /> */}
        <Route path="/logout" element={<Logout />} />
        <Route path=":code" element={<ZnsLink />} />
        {/* <Route path={eventConfig.SLUG_PARAMS.REGISTER} element={<Register />} /> */}
        {/* <Route path={eventConfig.SLUG_PARAMS.XACNHAN1} element={<XacNhan2 />} /> */}
        {/* <Route path={eventConfig.SLUG_PARAMS.LOGIN} element={<Login />} /> */}
        <Route
          element={(
            <MainLayoutContainer />
          )}
        >
          {
            routesPhase2.map((item, index) => (item.isAuth ? (
              <Route
                path={item.paths}
                key={`route-${index.toString()}`}
                element={(
                  <PrivateRoute
                    outlet={item.element}
                  />
                )}
              />
            ) : (
              <Route
                key={`router-${item.element}-${index.toString()}`}
                path={item.paths}
                element={item.element}
              />
            )))
          }
        </Route>
        {/* <Route path="/dongthuan" element={<InteractiveOffline />} /> */}
      </Routes>
    </Suspense>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const AppWrapper: React.FC = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <Router>
        {/* <AppInitializer /> */}
        <App />
      </Router>
    </QueryClientProvider>
  </Provider>
);

export default AppWrapper;
