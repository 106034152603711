import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface NotifyState {
    voucherIdsShowSuccess?: Array<number>;
    voucherIdsShowError?: Array<number>;
    voucherIdsShowErrorOther?: Array<number>;
    voucherIdsHide?: Array<number>;
    voucherIdsShowErrorReveicedFull?: Array<number>;
    isLoading?: boolean;
}

const initialState: NotifyState = {
    voucherIdsShowSuccess: [],
    voucherIdsShowError: [],
    voucherIdsShowErrorOther: [],
    voucherIdsShowErrorReveicedFull: [],
    voucherIdsHide: []
};

export const notifySlice = createSlice({
    name: 'notify',
    initialState,
    reducers: {
        updateVoucherIdsShowSuccess: ($state, action: PayloadAction<Array<number>>) => {
            $state.voucherIdsShowSuccess = [
                ...$state.voucherIdsShowSuccess || [],
                ...action.payload
            ];
        },
        updateVoucherIdsShowError: ($state, action: PayloadAction<Array<number>>) => {
            $state.voucherIdsShowError = [...$state.voucherIdsShowError || [], ...action.payload];
        },
        updateVoucherIdsShowErrorOther: ($state, action: PayloadAction<Array<number>>) => {
            $state.voucherIdsShowErrorOther = [...$state.voucherIdsShowErrorOther || [],
            ...action.payload];
        },
        updateVoucherIdsHide: ($state, action: PayloadAction<Array<number>>) => {
            $state.voucherIdsHide = [...$state.voucherIdsHide || [], ...action.payload];
        },
        updateVoucherIdsShowErrorReveicedFull: ($state, action: PayloadAction<Array<number>>) => {
            $state.voucherIdsShowErrorReveicedFull = [
                ...$state.voucherIdsShowErrorReveicedFull || [],
                ...action.payload
            ];
        },
        resetVoucherNotify: ($state) => {
            $state.voucherIdsHide = [];
            $state.voucherIdsShowError = [];
            $state.voucherIdsShowSuccess = [];
            $state.voucherIdsShowErrorOther = [];
            $state.voucherIdsShowErrorReveicedFull = [];
        }
    },
    extraReducers() { },
});

export const {
    updateVoucherIdsShowSuccess,
    updateVoucherIdsHide,
    updateVoucherIdsShowError,
    updateVoucherIdsShowErrorReveicedFull,
    resetVoucherNotify,
    updateVoucherIdsShowErrorOther
} = notifySlice.actions;

export default notifySlice.reducer;
