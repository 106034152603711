import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import getSystemService from 'services/system';
import { GeneralDataSystem } from 'services/system/types';

interface GeneralState {
  data?: GeneralDataSystem;
  loading?: boolean;
  socketStatus?: number;
  resume?: boolean;
}

const initialState: GeneralState = {
  data: undefined,
  loading: false,
  resume: false,
};

export const getSystemAction = createAsyncThunk<
  GeneralDataSystem,
  string | undefined,
  { rejectValue: ErrorResponse }
>('general/getSystemAction', async (params, { rejectWithValue }) => {
  try {
    const res = await getSystemService(params);
    return res;
  } catch (error) {
    return rejectWithValue(error as ErrorResponse);
  }
});

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setSocketStatus: ($state, action: PayloadAction<number>) => {
      $state.socketStatus = action.payload;
    },
    setResume: ($state, action: PayloadAction<boolean>) => {
      $state.resume = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSystemAction.pending, ($state) => {
      $state.loading = true;
    });
    builder.addCase(getSystemAction.fulfilled, ($state, action) => {
      $state.data = action.payload;
      $state.loading = false;
    });
    builder.addCase(getSystemAction.rejected, ($state) => {
      $state.loading = false;
    });
  },
});

export const {
  setSocketStatus, setResume
} = generalSlice.actions;

export default generalSlice.reducer;
