import {
  QuestionDataTypes, QuestionInteractiveResponse,
  QuestionInteractiveTypesParams, QuestionParamsTypes
} from './type';

import axiosInstance from 'services/common/instance';

const getQuestionServices = async (): Promise<QuestionDataTypes[]> => {
  const res = await axiosInstance.get('questions');
  return res.data.data;
};

export const getInteractiveActivityQuestionService = async ():
  Promise<QuestionInteractiveResponse> => {
  const res = await axiosInstance.get('interactive-activity-questions');
  return res.data.data;
};

export const postQuestionLivestreamService = async (params: QuestionParamsTypes): Promise<void> => {
  const res = await axiosInstance.post('questions', params);
  return res.data;
};

export const postQuestionInteractiveService = async (
  params: QuestionInteractiveTypesParams
): Promise<void> => {
  await axiosInstance.post('members/interactive-answers', params);
};

export default getQuestionServices;
