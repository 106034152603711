import { AddVoucherInCartParamsTypes, MemberProfileTypes, TrackingOnlineParamsTypes } from './type';

import axiosInstance from 'services/common/instance';
import { VoucherCurrentSessionTypes } from 'services/livestream/types';

const getMemberProfileService = async (): Promise<MemberProfileTypes> => {
  const res = await axiosInstance.get('members/profile');
  return res.data.data;
};

export const getVoucherCartOfMemberService = async (): Promise<VoucherCurrentSessionTypes> => {
  const res = await axiosInstance.get('members/voucher-cart');
  return res.data.data;
};

export const addVoucherCartOfMemberService = async (params: AddVoucherInCartParamsTypes)
  : Promise<void> => {
  const res = await axiosInstance.post('members/voucher-carts/add', params);
  return res.data;
};

export const exchangeVoucherCartOfMemberService = async (id: number)
  : Promise<void> => {
  const res = await axiosInstance.post(`members/voucher-carts/${id}/exchange`);
  return res.data;
};

export const pushEyeMemberService = async ()
  : Promise<void> => {
  const res = await axiosInstance.post('members/eye');
  return res.data;
};

export const trackingOnlineMemberService = async (id: number, params: TrackingOnlineParamsTypes)
  : Promise<void> => {
  const res = await axiosInstance.post(`members/online/${id}`, params);
  return res.data;
};

export default getMemberProfileService;
