import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import userReducer from './auth';
import commentReducer from './comment';
import configsReducer from './configs';
import exampleReducer from './example';
import liveStreamReducer from './liveStream';
import notifyReducer from './notify';
import profileReducer from './profile';
import questionsReducer from './questions';
import systemReducer from './system';

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  reducer: {
    example: exampleReducer,
    user: userReducer,
    system: systemReducer,
    profile: profileReducer,
    configs: configsReducer,
    questions: questionsReducer,
    liveStream: liveStreamReducer,
    comments: commentReducer,
    notify: notifyReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
