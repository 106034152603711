import { getTokenSession } from 'services/common/storage';

export const SocketStatus = {
  CONNECTING: 1,
  CONNECTED: 2,
  DISCONNECTED: 3,
};
interface WsOptions {
  onMessage?: (data: string) => void;
  onStateChange?: (status: number) => void;
  pingInterval: number;
  pongTimeout: number;
  initBackoff: number;
}

const DEFAULT_OPTIONS: WsOptions = {
  pingInterval: 30000,
  pongTimeout: 5000,
  initBackoff: 1000,
};

const socket = (
  url: string,
  getToken: () => Promise<string>,
  options: Partial<WsOptions> = {}
) => {
  const opts: WsOptions = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  let ws: WebSocket;
  let backOff = opts.initBackoff / 2;
  let backOffHandle: NodeJS.Timer | null;
  let forceClose = false;
  let intervalHandle: NodeJS.Timer;
  let pongTimeoutHandle: NodeJS.Timeout;

  const interruptedByUser = () => {
    if (intervalHandle) {
      clearInterval(intervalHandle);
    }
    if (pongTimeoutHandle) {
      clearTimeout(pongTimeoutHandle);
    }

    if (backOffHandle) {
      clearTimeout(backOffHandle);
      backOffHandle = null;
    }
  };

  async function initWs() {
    backOffHandle = null;

    const onclose = () => {
      opts.onStateChange?.(SocketStatus.DISCONNECTED);

      if (forceClose) {
        console.log('WS: Force closed');
        return;
      }

      if (!getTokenSession()) {
        interruptedByUser();
        return;
      }

      if (backOff >= opts.initBackoff) {
        console.log('WS: Backoff reconnect');
      } else {
        clearTimeout(pongTimeoutHandle);
        clearInterval(intervalHandle);
        console.log('WS: Closed connection');
      }

      // reconnect
      backOff = Math.min(backOff * 2, 60000);
      console.log('WS: Reconnect in', backOff, 'ms');
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      backOffHandle = setTimeout(initWs, backOff);
    };

    try {
      if (!getTokenSession()) {
        interruptedByUser();
        return;
      }
      const qs = `?token=${await getToken()}`;
      ws = new WebSocket(`${url}${qs}`);

      ws.onopen = () => {
        opts.onStateChange?.(SocketStatus.CONNECTED);

        if (backOff >= opts.initBackoff) {
          console.log('WS: Reconnected');

          console.log('WS: Reset backoff to default');
          backOff = opts.initBackoff / 2;
        } else {
          console.log('WS: Opened connection');
        }

        intervalHandle = setInterval(() => {
          ws.send('ping');

          pongTimeoutHandle = setTimeout(() => {
            console.log('WS: Server not responding');
            clearInterval(intervalHandle);
            ws.close();
          }, opts.pongTimeout);
        }, opts.pingInterval);
      };

      ws.onerror = (event) => {
        console.log('WS Error:', event);
      };

      ws.onclose = onclose;

      ws.onmessage = function (event) {
        if (event.data === 'pong') {
          clearTimeout(pongTimeoutHandle);
        } else {
          opts.onMessage?.(event.data);
        }
      };
    } catch (error) {
      console.log('WS Unexpected Error', error);
      onclose();
    }
  }

  // handle reconnect when detect network change
  const onNetworkChange = () => {
    if (navigator.onLine) {
      console.log('WS: Network online');

      if (backOffHandle) {
        clearTimeout(backOffHandle);
        initWs();
      }
    }
  };

  initWs();

  window.addEventListener('online', onNetworkChange);

  return () => {
    window.removeEventListener('online', onNetworkChange);
    forceClose = true;
    ws.close();
    interruptedByUser();
  };
};

export default socket;
