import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import loginEventService, { getConditionalListService } from 'services/auth';
import { ConditionalListTypes, LoginParamsTypes, UserInfoDataTypes } from 'services/auth/type';
import { initialTokenSession } from 'services/common/storage';
import getTokenWebSocketService from 'services/websocket';

interface UserState {
  loading: boolean;
  data?: UserInfoDataTypes;
  logged: boolean;
  conditional?: ConditionalListTypes;
  wsToken?: string;
}

const initialState: UserState = {
  data: undefined,
  loading: false,
  logged: false,
  conditional: undefined,
  wsToken: undefined
};

export const getConditionalListAction = createAsyncThunk<
  ConditionalListTypes,
  void,
  { rejectValue: ErrorResponse }
>('conditinalReducer/getConditionalListAction', async (_, { rejectWithValue }) => {
  try {
    const res = await getConditionalListService();
    return res;
  } catch (error) {
    return rejectWithValue(error as ErrorResponse);
  }
});

export const getUserLoginAction = createAsyncThunk<
  UserInfoDataTypes,
  LoginParamsTypes,
  { rejectValue: ErrorResponse }
>(
  'loginReducer/getUserLoginAction',
  async (params: LoginParamsTypes, { rejectWithValue }) => {
    try {
      const result = await loginEventService(params);
      if (result.token) {
        initialTokenSession({ accessToken: result.token });
      }
      return result;
    } catch (error) {
      return rejectWithValue(error as ErrorResponse);
    }
  }
);

export const getWsTokenAction = createAsyncThunk<
  string,
  void,
  { rejectValue: ErrorResponse }
>(
  'loginReducer/getWsTokenAction',
  async (_, { rejectWithValue }) => {
    try {
      const result = await getTokenWebSocketService();
      return result;
    } catch (error) {
      return rejectWithValue(error as ErrorResponse);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getUserLoginAction.pending, ($state) => {
      $state.loading = true;
    });
    builder.addCase(getUserLoginAction.fulfilled, ($state, action) => {
      $state.data = action.payload;
      $state.loading = false;
      $state.logged = !action.payload.error;
    });
    builder.addCase(getUserLoginAction.rejected, ($state) => {
      $state.loading = false;
    });
    builder.addCase(getConditionalListAction.fulfilled, ($state, action) => {
      $state.conditional = action.payload;
    });
    builder.addCase(getWsTokenAction.fulfilled, ($state, action) => {
      $state.wsToken = action.payload;
    });
  },
});

export default userSlice.reducer;
