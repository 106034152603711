import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';

import { clearTokenSession, getTokenSession } from './storage';

import eventConfig from 'configs';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  ($config: AxiosRequestConfig): AxiosRequestConfig => {
    if ($config.headers) {
      const token = getTokenSession();
      if (token) {
        $config.headers.Authorization = `Bearer ${token.accessToken}`;
      }
      $config.headers['Content-Type'] = 'application/json';
      $config.headers.Accept = 'application/json';
    }
    return $config;
  },
  async (error: AxiosError): Promise<AxiosError> => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => response,
  async (error: AxiosError): Promise<AxiosError> => {
    if (Array.isArray((error.response?.data as any)?.errors)
      && (error.response?.data as any)?.errors[0]?.code === 401) {
      clearTokenSession();
      window.location.href = window.location.origin + eventConfig.SLUG_PARAMS.HOME;
    }
    return Promise.reject(
      error.response ? (error.response.data as any).errors : error,
    );
  },
);
export default axiosInstance;
