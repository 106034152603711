import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import getMemberProfileService from 'services/member';
import { MemberProfileTypes } from 'services/member/type';

interface ProfileState {
  data?: MemberProfileTypes;
  // totalSkus?: number;
  isLoading?: boolean;
}

const initialState: ProfileState = {
  data: undefined,
  // totalSkus: 0,
  isLoading: false,
};

export const getMemberProfileAction = createAsyncThunk<
  MemberProfileTypes,
  void,
  { rejectValue: ErrorResponse }
>('profile/getMemberProfileAction', async (_, { rejectWithValue }) => {
  try {
    const res = await getMemberProfileService();
    return res;
  } catch (error) {
    return rejectWithValue(error as ErrorResponse);
  }
});

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateTotalSkus: ($state, action: PayloadAction<number>) => {
      if ($state.data) {
        $state.data.totalSkus = action.payload;
      }
    },
    logout: ($state) => {
      $state.data = undefined;
    }
  },
  extraReducers(builder) {
    builder.addCase(getMemberProfileAction.pending, ($state) => {
      $state.isLoading = true;
    });
    builder.addCase(getMemberProfileAction.fulfilled, ($state, action) => {
      $state.data = action.payload;
      // $state.totalSkus = action.payload.totalSkus;
      $state.isLoading = false;
    });
    builder.addCase(getMemberProfileAction.rejected, ($state) => {
      $state.isLoading = false;
    });
  },
});

export const { updateTotalSkus, logout } = profileSlice.actions;

export default profileSlice.reducer;
